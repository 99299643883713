<template>
  <ToolsFormEdgeDistanceEC />
</template>

<script>
import ToolsFormEdgeDistanceEC from '../../components/tools/ToolsFormEdgeDistanceEC.vue';

export default {
  components: {
    ToolsFormEdgeDistanceEC,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
